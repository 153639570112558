<template>
  <AwardsTable/>
</template>
<script>
import AwardsTable from './AwardsTable';

export default {
  components: {
    AwardsTable
  },
  props: {}
};
</script>