<template>
  <CIDataTable
    :vuex="vuex"
    :settings="settings"
    :route="route"
    :isCreateButtonVisible="false"
    :isShowCreateOrLoad="false"
    model="awards"
  />
</template>
<script>
import { awards } from '@/config/descriptions/awards';
import { awardsTexts } from '@/config/pageTexts/awards.json';

export default {
  name: 'AwardsTable',
  data() {
    return {
      vuex: {
        getter: {
          theme: 'theme/theme',
          data: 'awards/awards',
          fields: 'awards/fields',
          sortableFields: 'awards/sortableFields',
          pages: 'awards/pages',
          loading: 'awards/loading',
          resultCount: 'awards/resultCount',
          totalRecords: 'awards/totalRecords',
          originalFields: 'awards/originalFields',
          searchableAllFields: 'awards/searchableAllFields',
          success: 'awards/success',
          message: 'awards/message',
        },
        actions: {
          fields: 'awards/handleFields',
          delete: 'awards/handleDeleteAwards',
          reset: 'awards/handleClearFilter',
          query: 'awards/handleGetAwardsByQuery',
        },
      },
      settings: {
        describe: { ...awards },
        page: { ...awardsTexts },
      },
      route: {
        create: 'CreateAward',
        edit: 'EditAward',
        preview: 'PreviewAward',
      },
    };
  },
};
</script>